import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"

import "../styles/careers.scss"
import {StaticImage} from "gatsby-plugin-image";

const CareersPage = (props) => (

    <LayoutMain>
        <Helmet>
            <title>AffirmXH Careers</title>
            <meta
                name="Careers"
                content="AffirmXH Careers"
            />
            <bodyAttributes
                className="careers"
            />
        </Helmet>


        <div className="container-careers">
            <div className="mainImage">
                <StaticImage
                    src="../assets/images/page-images/holding-phone-2.jpg"
                    alt="Careers"
                    placeholder="blurred"
                    layout="fullWidth"
                    quality={100}
                />
            </div>
            <div className="careersCopy">
                <h1>Careers</h1>
                <p>We are always looking for good people to join our team.  As a remote company, we focus on goals and results instead of time in the office. If you are interested in talking with us, drop us an email at hello@affirmxh.com.  Send your resume, and let us know why you want to work with us.</p>
                <p>&nbsp;</p>
                <p>There are currently no active positions, but we are always looking to meet good people.</p>

            </div>
        </div>

    </LayoutMain>
)

export default CareersPage



